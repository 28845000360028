<template>
    <div class="table-box">
        <el-table
            v-if="auditStatus == '待处理'"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :key="1"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '600',
            }"
        >
            <el-table-column label="时间" show-overflow-tooltip width="150">
                <template slot-scope="scope">
                    <span>{{
                        scope.row.applyTime ? scope.row.applyTime : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="10"> </el-table-column>
            <el-table-column label="编号" show-overflow-tooltip width="150">
                <template slot-scope="scope">
                    <span>{{ scope.row.numberNo || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="10"> </el-table-column>
            <el-table-column label="部门" show-overflow-tooltip width="93">
                <template slot-scope="scope">
                    <span>{{ scope.row.departmentName || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="10"> </el-table-column>
            <el-table-column label="申请人" show-overflow-tooltip width="93">
                <template slot-scope="scope">
                    <span>{{
                        scope.row.adminName ? scope.row.adminName : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="10"> </el-table-column>
            <el-table-column label="名称" show-overflow-tooltip width="200">
                <template slot-scope="scope">
                    <span>{{ scope.row.customerName || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="10"> </el-table-column>
            <el-table-column label="事项" width="150">
                <template slot-scope="scope">
                    <el-button
                        style="color: #2370eb"
                        @click="onHandle(scope.row)"
                        type="text"
                    >
                        {{ returnText(scope.row) }}
                    </el-button>
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <el-table
            v-if="auditStatus == '已处理'"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :key="2"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '600',
            }"
        >
            <el-table-column
                v-if="auditStatus == '已处理'"
                width="55"
                align="center"
            >
                <template slot-scope="scope">
                    <el-radio
                        v-model="tableRadio"
                        :label="scope.row"
                        v-if="handleDisable(scope.row)"
                    >
                        <i></i>
                    </el-radio>
                </template>
            </el-table-column>
            <el-table-column label="时间" show-overflow-tooltip width="160">
                <template slot-scope="scope">
                    <span>{{
                        scope.row.applyTime ? scope.row.applyTime : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="10"> </el-table-column>
            <el-table-column label="编号" show-overflow-tooltip width="150">
                <template slot-scope="scope">
                    <span>{{ scope.row.numberNo || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="10"> </el-table-column>
            <el-table-column label="部门" show-overflow-tooltip width="93">
                <template slot-scope="scope">
                    <span>{{ scope.row.departmentName || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="10"> </el-table-column>
            <el-table-column label="申请人" show-overflow-tooltip width="93">
                <template slot-scope="scope">
                    <span>{{
                        scope.row.adminName ? scope.row.adminName : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="10"> </el-table-column>
            <el-table-column label="名称" show-overflow-tooltip width="200">
                <template slot-scope="scope">
                    <span>{{ scope.row.customerName || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="10"> </el-table-column>
            <el-table-column label="事项" width="150">
                <template slot-scope="scope">
                    <el-button
                        style="color: #2370eb"
                        @click="onHandleDetails(scope.row)"
                        type="text"
                    >
                        {{ returnText(scope.row) }}
                    </el-button>
                </template>
            </el-table-column>
            <el-table-column min-width="10"> </el-table-column>
            <el-table-column label="状态" width="150">
                <template slot-scope="scope">
                    <el-button style="color: #606266" type="text">
                        {{ returnResult(scope.row) }}
                    </el-button>
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
    </div>
</template>
<script>
import { approvalList } from '@/api/workbench/audit.js';
import { reportUpdate, reportGet } from '@/api/report/newReport.js';
export default {
    components: {},
    data() {
        return {
            tableData: [],
            btnP: {},
            userType: sessionStorage.getItem('userType'),
            auditStatus: '',
            tableRadio: '',
            pageData: {},
        };
    },
    methods: {
        // 获取列表
        getData(searchVal, pageNum, pageSize, btnP) {
            let data = {
                param: {},
                pageNum: pageNum,
                pageSize: pageSize,
            };
            if (searchVal.name) {
                data.param.name = searchVal.name;
            }
            if (searchVal.createDate) {
                data.param.createDate = searchVal.createDate;
            }
            this.auditStatus = searchVal.auditStatus;

            if (searchVal.auditStatus == '待处理') {
                if (
                    this.userType == 8 ||
                    this.userType == 9 ||
                    this.userType == 10
                ) {
                    data.param.typeList = [3, 5];
                    data.param.declareStatusList = [1];
                    data.param.reportStatusList = [11, 12];
                    // 发票
                    if (searchVal.type[0] == 2 && searchVal.type[1] == 1) {
                        this.tableData = [];
                        this.$emit('totalNum', 0);
                        return;
                    }
                    // 报备延期
                    if (searchVal.type[0] == 2 && searchVal.type[1] == 2) {
                        data.param.typeList = [5];
                        data.param.reportStatusList = [11, 12];
                    }
                    // 充值
                    if (searchVal.type[0] == 3 && searchVal.type[1] == 1) {
                        this.tableData = [];
                        this.$emit('totalNum', 0);
                        return;
                    }
                    // 申报
                    if (searchVal.type[0] == 3 && searchVal.type[1] == 2) {
                        data.param.typeList = [3];
                        data.param.declareStatusList = [1];
                    }
                    // 报销
                    if (searchVal.type[0] == 3 && searchVal.type[1] == 3) {
                        this.tableData = [];
                        this.$emit('totalNum', 0);
                        return;
                    }
                }
                // 财务
                if (this.userType == 6) {
                    data.param.typeList = [1, 2];
                    data.param.invoiceStatusList = [1];
                    data.param.rechargeStatusList = [2];
                    // 发票
                    if (searchVal.type[0] == 2 && searchVal.type[1] == 1) {
                        data.param.typeList = [1];
                        data.param.invoiceStatusList = [1];
                        delete data.param.rechargeStatusList;
                    }
                    // 充值
                    if (searchVal.type[0] == 3 && searchVal.type[1] == 1) {
                        data.param.typeList = [2];
                        data.param.rechargeStatusList = [2];
                        delete data.param.invoiceStatusList;
                    }
                    // 申报
                    if (searchVal.type[0] == 3 && searchVal.type[1] == 2) {
                        this.tableData = [];
                        this.$emit('totalNum', 0);
                        return;
                    }
                    // 报销
                    if (searchVal.type[0] == 3 && searchVal.type[1] == 3) {
                        this.tableData = [];
                        this.$emit('totalNum', 0);
                        return;
                    }
                }
                // boss
                if (this.userType == 15) {
                    data.param.typeList = [2, 3, 4, 5];
                    data.param.declareStatusList = [2];
                    data.param.expenseStatusList = [2, 4];
                    data.param.rechargeStatusList = [1];
                    data.param.reportStatusList = [20, 21];
                    // 发票
                    if (searchVal.type[0] == 2 && searchVal.type[1] == 1) {
                        this.tableData = [];
                        this.$emit('totalNum', 0);
                        return;
                    }
                    // 报备延期
                    if (searchVal.type[0] == 2 && searchVal.type[1] == 2) {
                        data.param.typeList = [5];
                        data.param.reportStatusList = [20, 21];
                    }
                    // 充值
                    if (searchVal.type[0] == 3 && searchVal.type[1] == 1) {
                        data.param.typeList = [2];
                        data.param.rechargeStatusList = [1];
                        delete data.param.declareStatusList;
                        delete data.param.expenseStatusList;
                    }
                    // 申报
                    if (searchVal.type[0] == 3 && searchVal.type[1] == 2) {
                        data.param.typeList = [3];
                        data.param.declareStatusList = [2];
                        delete data.param.expenseStatusList;
                        delete data.param.rechargeStatusList;
                    }
                    // 报销
                    if (searchVal.type[0] == 3 && searchVal.type[1] == 3) {
                        data.param.typeList = [4];
                        data.param.expenseStatusList = [2, 4];
                        delete data.param.rechargeStatusList;
                        delete data.param.declareStatusList;
                    }
                }
            }
            if (searchVal.auditStatus == '已处理') {
                if (
                    this.userType == 8 ||
                    this.userType == 9 ||
                    this.userType == 10
                ) {
                    data.param.typeList = [3];
                    data.param.declareStatusList = [4, 5, 6, 7, 11, 12];
                    // 发票
                    if (searchVal.type[0] == 2 && searchVal.type[1] == 1) {
                        this.tableData = [];
                        this.$emit('totalNum', 0);
                        return;
                    }
                    // 报备延期
                    if (searchVal.type[0] == 2 && searchVal.type[1] == 2) {
                        this.tableData = [];
                        this.$emit('totalNum', 0);
                        return;
                    }
                    // 充值
                    if (searchVal.type[0] == 3 && searchVal.type[1] == 1) {
                        this.tableData = [];
                        this.$emit('totalNum', 0);
                        return;
                    }
                    // 申报
                    if (searchVal.type[0] == 3 && searchVal.type[1] == 2) {
                        data.param.typeList = [3];
                        data.param.declareStatusList = [4, 5, 6, 7, 11, 12];
                    }
                    // 报销
                    if (searchVal.type[0] == 3 && searchVal.type[1] == 3) {
                        this.tableData = [];
                        this.$emit('totalNum', 0);
                        return;
                    }
                }
                // 财务
                if (this.userType == 6) {
                    data.param.typeList = [1, 2];
                    data.param.invoiceStatusList = [2, 3];
                    data.param.rechargeStatusList = [4];
                    // 发票
                    if (searchVal.type[0] == 2 && searchVal.type[1] == 1) {
                        data.param.typeList = [1];
                        data.param.invoiceStatusList = [2, 5];
                        delete data.param.rechargeStatusList;
                    }
                    // 报备延期
                    if (searchVal.type[0] == 2 && searchVal.type[1] == 2) {
                        this.tableData = [];
                        this.$emit('totalNum', 0);
                        return;
                    }
                    // 充值
                    if (searchVal.type[0] == 3 && searchVal.type[1] == 1) {
                        data.param.typeList = [2];
                        data.param.rechargeStatusList = [2, 3];
                        delete data.param.invoiceStatusList;
                    }
                    // 申报
                    if (searchVal.type[0] == 3 && searchVal.type[1] == 2) {
                        this.tableData = [];
                        this.$emit('totalNum', 0);
                        return;
                    }
                    // 报销
                    if (searchVal.type[0] == 3 && searchVal.type[1] == 3) {
                        this.tableData = [];
                        this.$emit('totalNum', 0);
                        return;
                    }
                }
                // boss
                if (this.userType == 15) {
                    data.param.typeList = [1, 2, 3, 4];
                    data.param.invoiceStatusList = [2, 5];
                    data.param.declareStatusList = [4, 5, 7, 11, 12];
                    data.param.expenseStatusList = [7, 11];
                    data.param.rechargeStatusList = [4];
                    // 发票
                    if (searchVal.type[0] == 2 && searchVal.type[1] == 1) {
                        data.param.typeList = [1];
                        data.param.invoiceStatusList = [2, 5];
                        delete data.param.declareStatusList;
                        delete data.param.expenseStatusList;
                        delete data.param.rechargeStatusList;
                    }
                    // 报备延期
                    if (searchVal.type[0] == 2 && searchVal.type[1] == 2) {
                        this.tableData = [];
                        this.$emit('totalNum', 0);
                        return;
                    }
                    // 充值
                    if (searchVal.type[0] == 3 && searchVal.type[1] == 1) {
                        data.param.typeList = [2];
                        data.param.rechargeStatusList = [4];
                        delete data.param.declareStatusList;
                        delete data.param.expenseStatusList;
                    }
                    // 申报
                    if (searchVal.type[0] == 3 && searchVal.type[1] == 2) {
                        data.param.typeList = [3];
                        data.param.declareStatusList = [4, 5, 7, 11, 12];
                        delete data.param.expenseStatusList;
                        delete data.param.rechargeStatusList;
                    }
                    // 报销
                    if (searchVal.type[0] == 3 && searchVal.type[1] == 3) {
                        data.param.typeList = [4];
                        data.param.expenseStatusList = [7, 11];
                        delete data.param.rechargeStatusList;
                        delete data.param.declareStatusList;
                    }
                }
            }
            if (searchVal.departmentId) {
                data.param.departmentId = searchVal.departmentId;
            }
            if (searchVal.adminId) {
                data.param.adminId = searchVal.adminId;
            }

            if (!btnP.depSearch) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            }
            this.pageData = {
                searchVal,
                pageNum,
                pageSize,
            };
            approvalList(data).then((res) => {
                if (res.code == 200) {
                    this.tableData = res.data.list;
                    this.$emit('totalNum', res.data.total);
                }
            });
        },

        onHandle(row) {
            if (row.type == 1) {
                this.onInvoice(row);
            }
            if (row.type == 2) {
                if (row.status == 1) {
                    this.onRecharge(row);
                }
                if (row.status == 2) {
                    this.onRechargePay(row);
                }
            }
            if (row.type == 3) {
                if (row.status == 4) {
                    this.onDelcarePay(row);
                } else {
                    this.onDeclare(row);
                }
                sessionStorage.setItem(
                    'pageData',
                    JSON.stringify(this.pageData)
                );
            }
            if (row.type == 4) {
                if (row.status == 2) {
                    this.onReimb(row);
                }
                if (row.status == 4) {
                    this.onReimbPay(row);
                }
            }
            if (row.type == 5) {
                this.onReport(row);
            }
        },
        onHandleDetails(row) {
            if (row.type == 1) {
                this.onInvoiceDetails(row);
            }
            if (row.type == 2) {
                this.onRechargeDetails(row);
            }
            if (row.type == 3) {
                this.onDeclareDetails(row);
                sessionStorage.setItem(
                    'pageData',
                    JSON.stringify(this.pageData)
                );
            }
            if (row.type == 4) {
                this.onReimbDetails(row);
            }
            if (row.type == 5) {
                this.onReportDetais(row);
            }
        },
        onInvoice(row) {
            this.$emit('onInvoice', row);
        },
        onInvoiceDetails(row) {
            this.$emit('onInvoiceDetails', row);
        },
        onDeclare(row) {
            this.$emit('onDeclare', row);
        },
        onDeclareDetails(row) {
            this.$emit('onDeclareDetails', row);
        },
        onDelcarePay(row) {
            this.$emit('onDelcarePay', row);
        },
        onReimb(row) {
            this.$emit('onReimb', row);
        },
        onReimbDetails(row) {
            this.$emit('onReimbDetails', row);
        },
        onReimbPay(row) {
            this.$emit('onReimbPay', row);
        },
        onRecharge(row) {
            this.$emit('onRecharge', row);
        },
        onRechargePay(row) {
            this.$emit('onRechargePay', row);
        },
        onRechargeDetails(row) {
            this.$emit('onRechargeDetails', row);
        },
        onReport(row) {
            reportGet({ param: { id: row.id } }).then((result) => {
                if (
                    (result.data.reportStatus == 11 &&
                        (this.userType == 8 ||
                            this.userType == 9 ||
                            this.userType == 10)) ||
                    (result.data.reportStatus == 20 && this.userType == 15)
                ) {
                    let data = {
                        param: {
                            id: row.id,
                            reportStatus: result.data.reportStatus + 1,
                        },
                    };
                    reportUpdate(data).then((res) => {
                        if (res.code == 200) {
                            result.data.reportStatus =
                                result.data.reportStatus + 1;
                            this.$emit('onReport', result.data);
                        }
                    });
                } else {
                    if (
                        result.data.handleAdminId ==
                        sessionStorage.getItem('adminId')
                    ) {
                        this.$emit('onReport', result.data);
                    } else {
                        this.$message.error('当前申请已被其他人操作');
                    }
                }
            });
        },
        onReportDetais(row) {
            this.$emit('onReportDetais', row);
        },
        returnText(item) {
            if (item.type == 1) {
                return '发票';
            }
            if (item.type == 2) {
                return '充值';
            }
            if (item.type == 3) {
                if (item.status == 4 && item.payWay == 2) {
                    return '申报支付确认';
                }
                return '销售费用申报';
            }
            if (item.type == 4) {
                if (item.status == 4) {
                    return '报销支付确认';
                }
                return '报销';
            }
            if (item.type == 5) {
                return '报备延期';
            }
        },
        returnResult(item) {
            if (item.type == 1) {
                return item.status == 1
                    ? '待审批'
                    : item.status == 2
                    ? '已通过'
                    : item.status == 3
                    ? '已驳回'
                    : item.status == 4
                    ? '已撤销'
                    : item.status == 5
                    ? '已开票'
                    : item.status == 6
                    ? '已作废'
                    : '- -';
            }
            if (item.type == 2) {
                return this.$tableDataHandle.rechargeStatus(item.status);
            }
            if (item.type == 3) {
                return this.$cost.costStatus(item.status);
            }
            if (item.type == 4) {
                return this.$cost.reimbStatus(item.status);
            }
            if (item.type == 5) {
                return this.$MailStatus.reportStatus(item.status);
            }
        },
        handleDisable(row) {
            if (
                (this.returnResult(row) == '待支付' &&
                    this.returnText(row) == '销售费用申报') ||
                (this.returnResult(row) == '已通过' &&
                    this.returnText(row) == '发票')
            ) {
                return true;
            } else {
                return false;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}

.icon-dianhua,
.icon-weixin,
.icon-QQ,
.icon-diannao,
.icon-youxiang,
.icon-youxiang1,
.icon-duanxinxiaoxixinxi,
.icon-qita,
.icon-yuyuebaifang,
.icon-qita1 {
    position: static;
    left: -10px !important;
}
.table-box {
    width: 100%;
    height: 100%;
    background-color: #fff;
}

.copy {
    height: 20px;
    background: #fff1d9;
    border-radius: 20px;
    line-height: 20px;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    display: inline-block;
    padding: 0 8px 0 25px;
    color: #ff9c39;
    i {
        position: absolute;
        left: -10px;
        top: -10px;
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #ff9c39;
        color: #fff;
        text-align: center;
        line-height: 40px;
        font-size: 20px;
        transform: scale(0.5);
    }
}
.copy2 {
    height: 20px;
    background: #fff1d9;
    border-radius: 20px;
    line-height: 20px;

    font-size: 12px;
    display: inline-block;
    padding: 0 8px 0 25px;
    color: #ff9c39;
    position: relative;
    i {
        position: absolute;
        left: -10px;
        top: -10px;
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #ff9c39;
        color: #fff;
        text-align: center;
        line-height: 40px;
        font-size: 20px;
        transform: scale(0.5);
    }
}
.way_box_fs {
    line-height: 30px;
    em {
        display: inline-block;
        width: 30px;
        font-style: normal;
    }
    .contactContent {
        width: 120px !important;
        display: inline-block;
    }
}
</style>
<style>
.el-checkbox__input.is-disabled .el-checkbox__inner {
    display: none;
}
</style>
