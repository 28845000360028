<template>
    <div class="audit_box">
        <div class="detail_box">
            <div style="background: #fff; padding: 14px 34px 20px 20px">
                <p class="detail_title">
                    {{ invoiceDetail.adminName }}提交的申报申请
                </p>
                <Apply ref="apply"></Apply>
                <p class="detail_title invoice_info">发票信息</p>
                <Invoice ref="invoice"></Invoice>
                <p class="detail_title audit">审批</p>
                <el-form label-position="left" label-width="80px" size="small">
                    <el-form-item label="审批结果" required>
                        <el-radio-group v-model="auditData.status">
                            <el-radio :label="1">通过</el-radio>
                            <el-radio :label="0">驳回</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="审批备注">
                        <el-input
                            style="width: 270px"
                            type="textarea"
                            rows="5"
                            v-model="auditData.remark"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </div>

        <div slot="footer" class="bottom-but">
            <el-button
                @click="onClose"
                round
                style="
                    padding: 7px 20px;
                    width: 80px;
                    height: 26px;
                    border-radio: 26px;
                "
                size="mini"
                >取 消</el-button
            >
            <el-button
                type="primary"
                style="
                    margin-right: 15px;
                    background: #2370eb;
                    padding: 7px 20px;
                    width: 80px;
                    height: 26px;
                    border-radio: 26px;
                "
                round
                size="mini"
                @click="onSubmit"
                >保 存</el-button
            >
        </div>
    </div>
</template>

<script>
import { invoiceAudit } from '@/api/contr/invoice.js';
import Apply from './components/apply.vue';
import Invoice from './components/invoice.vue';
export default {
    name: '',
    props: {},
    data() {
        return {
            invoiceDetail: {},
            auditData: {},
        };
    },
    watch: {},
    components: {
        Apply,
        Invoice,
    },
    created() {},
    methods: {
        async getData(data) {
            this.invoiceDetail = data;
            setTimeout(() => {
                this.$refs.apply.getData(data);
                this.$refs.invoice.getData(data);
            }, 0);
        },
        onSubmit() {
            if (this.auditData.status == 2) {
                return this.$message.error('请选择审批结果');
            }

            let data = {
                param: {
                    invoiceId: this.invoiceDetail.id,
                    remark: this.auditData.remark,
                    status: this.auditData.status,
                },
            };
            invoiceAudit(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('审批成功');
                    this.onClose();
                }
            });
        },
        onClose() {
            this.auditData = {};
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}

.audit_box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .line {
        height: 9px;
        background: #f9f9f9;
        width: 480px;
        margin-left: -20px;
    }
    .detail_box {
        flex: 1;
        display: flex;
        overflow: auto;
        flex-direction: column;
    }
    > div {
        font-size: 14px;
    }
    .detail_title {
        margin-bottom: 8px;
        background: #e9f2ff;
        border-radius: 2px;
        padding-left: 15px;
        font-size: 14px;
        border: 1px dashed rgba(35, 112, 235, 0.65);
        height: 30px;
        line-height: 30px;
        color: #333;
    }
    .audit {
        background: #e5f5dc;
        margin-top: 16px;
        border: 1px dashed rgba($color: #9fc877, $alpha: 0.65);
    }
    .invoice_info {
        border: 1px dashed rgba($color: #feb516, $alpha: 0.65);
        background-color: #fff0d0;
    }
    .bottom-but {
        width: 100%;
        height: 48px;
        background: #fff;
        display: flex;
        padding: 0;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        border-top: 1px solid #e5e5e5;
    }
    .el-form {
        padding-left: 15px;
    }
}
.comment_text {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}
</style>
