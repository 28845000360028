<template>
    <div class="audit_box">
        <div style="flex: 1; overflow-y: auto">
            <p class="detail_title">
                {{ declareDetail.adminName }}提交的申报申请
            </p>
            <DeclareInfo ref="declareInfo"></DeclareInfo>
            <p class="detail_title mingxi">明细</p>
            <Detailed ref="detailed"></Detailed>
            <p
                class="detail_title audit_remark"
                v-if="
                    declareDetail.declareAuditList &&
                    declareDetail.declareAuditList.length > 0
                "
            >
                审批备注
            </p>
            <AuditRemark ref="auditRemark"></AuditRemark>

            <div class="detail_title audit" v-if="declareDetail.payWay == 2">
                <div class="line"></div>
                审批
            </div>
            <el-form
                label-position="left"
                v-if="declareDetail.payWay == 2"
                label-width="80px"
                size="small"
            >
                <el-form-item label="支付确认" required>
                    <el-checkbox v-model="status">已确认支付</el-checkbox>
                </el-form-item>
            </el-form>
        </div>
        <div slot="footer" class="bottom-but">
            <el-button
                type="primary"
                v-if="userType == 15"
                style="
                    margin-right: 15px;
                    background: red;
                    border-color:red
                    padding: 7px 20px;
                    width: 80px;
                    height: 26px;
                    border-radio: 26px;
                "
                round
                size="mini"
                @click="declareZuofei"
                >作 废</el-button
            >
            <el-button
                @click="onClose"
                round
                style="
                    padding: 7px 20px;
                    width: 80px;
                    height: 26px;
                    border-radio: 26px;
                "
                size="mini"
                >取 消</el-button
            >

            <el-button
                type="primary"
                v-if="declareDetail.payWay == 2"
                style="
                    margin-right: 15px;
                    padding: 7px 20px;
                    width: 80px;
                    height: 26px;
                    border-radio: 26px;
                "
                round
                size="mini"
                :disabled="!status"
                @click="declareAudit"
                >确 定</el-button
            >
        </div>
    </div>
</template>

<script>
import { declareAudit, declareUpdate } from '@/api/cost/declare.js';
import DeclareInfo from './components/declareInfo.vue';
import Detailed from './components/detailed.vue';
import AuditRemark from './components/auditRemark.vue';
import { contracGet } from '@/api/contr/contr.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            declareDetail: {},
            auditData: {},
            status: '',
            userType: sessionStorage.getItem('userType'),
        };
    },
    watch: {},
    components: {
        DeclareInfo,
        Detailed,
        AuditRemark,
    },
    created() {},
    methods: {
        async getData(data) {
            this.declareDetail = data;
            if (data.contractId) {
                let res = await this.contracGet(data.contractId);
                if (res.code == 200) {
                    this.declareDetail.contract = res.data;
                }
            }
            setTimeout(() => {
                this.$refs.declareInfo.getData(this.declareDetail);
                this.$refs.detailed.getData(
                    this.declareDetail.declareDetailList
                );
                if (data.declareAuditList && data.declareAuditList.length > 0) {
                    this.$refs.auditRemark.getData(
                        this.declareDetail.declareAuditList
                    );
                }
            }, 0);
        },
        contracGet(id) {
            let data = {
                param: {
                    id,
                },
            };
            return contracGet(data);
        },
        //   审批
        async declareAudit() {
            if (this.declareDetail.contractId) {
                let res = await this.contracGet(this.declareDetail.contractId);
                if (res.code == 200) {
                    if (res.data.status == 3) {
                        this.$confirm(
                            '关联合同状态异常，是否变更为已确认支付？',
                            '提示',
                            {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                type: 'warning',
                            }
                        )
                            .then(() => {
                                let data = {
                                    param: {
                                        declareId: this.declareDetail.id,
                                        status: 2,
                                        remark: '已支付',
                                    },
                                };
                                declareAudit(data).then((res) => {
                                    if (res.code == 200 && res.data) {
                                        this.$message.success('操作成功');
                                        this.onClose();
                                    }
                                });
                            })
                            .catch(() => {});
                    } else {
                        let data = {
                            param: {
                                declareId: this.declareDetail.id,
                                status: 2,
                                remark: '已支付',
                            },
                        };
                        declareAudit(data).then((res) => {
                            if (res.code == 200 && res.data) {
                                this.$message.success('操作成功');
                                this.onClose();
                            }
                        });
                    }
                } else {
                    let data = {
                        param: {
                            declareId: this.declareDetail.id,
                            status: 2,
                            remark: '已支付',
                        },
                    };
                    declareAudit(data).then((res) => {
                        if (res.code == 200 && res.data) {
                            this.$message.success('操作成功');
                            this.onClose();
                        }
                    });
                }
            } else {
                let data = {
                    param: {
                        declareId: this.declareDetail.id,
                        status: 2,
                        remark: '已支付',
                    },
                };
                declareAudit(data).then((res) => {
                    if (res.code == 200 && res.data) {
                        this.$message.success('操作成功');
                        this.onClose();
                    }
                });
            }
        },
        declareZuofei() {
            this.$confirm('将标记当前报销的状态为已作废，是否确认？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let data = {
                        param: {
                            id: this.declareDetail.id,
                            status: 12,
                        },
                    };
                    declareUpdate(data).then((res) => {
                        if (res.code == 200) {
                            this.$message.success('操作成功');
                            this.onClose();
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    });
                });
        },
        onClose() {
            this.status = '';
            this.auditData = {};
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.audit_box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .line {
        height: 9px;
        background: #f9f9f9;
        width: 480px;
        position: absolute;
        left: -24px;
        top: -15px;
    }
    > div {
        padding: 14px 34px 0 20px;
        font-size: 14px;
    }
    .detail_title {
        margin-bottom: 8px;
        background: #e9f2ff;
        border-radius: 2px;
        padding-left: 15px;
        font-size: 14px;
        border: 1px dashed rgba(35, 112, 235, 0.65);
        height: 30px;
        line-height: 30px;
        color: #333;
        position: relative;
    }
    .audit_remark {
        background: #e5f5dc;
        border-color: rgba($color: #9fc877, $alpha: 0.65);
    }
    .mingxi {
        border: 1px dashed rgba($color: #feb516, $alpha: 0.65);
        background-color: #fff0d0;
    }
    .audit {
        background: #fff;
        margin-top: 27px;
        border: 1px dashed rgba($color: #fff, $alpha: 0.65);
        font-weight: 600;
    }
    .bottom-but {
        width: 100%;
        height: 48px;
        background: #fff;
        display: flex;
        padding: 0;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        border-top: 1px solid #e5e5e5;
    }
}
.el-form {
    padding-left: 20px;
}
.el-button--primary.is-disabled {
    color: #fff;
    background-color: #999;
    border-color: #999;
}
.el-button--primary {
    background-color: #2370eb;
    border-color: #2370eb;
    color: #fff;
}
</style>
