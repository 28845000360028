<template>
    <div class="apply_box">
        <div class="title">
            报备延期处理中
            <el-button @click="onStop" v-if="!isDetails">中止处理</el-button>
        </div>
        <div class="info">
            <el-form
                ref="form"
                label-position="left"
                size="small"
                label-width="90px"
            >
                <el-form-item label="报备品牌" style="margin-left: -10px">
                    <span class="brand">腾讯企业邮箱</span>
                </el-form-item>
                <el-form-item label="报备名称" style="margin-left: -10px">
                    <span class="brand">{{
                        tableRow.reportName || '- -'
                    }}</span>
                    <el-button
                        type="text"
                        style="color: #2370eb; font-size: 16px; padding: 6px 0"
                        @click.stop="onCopy(tableRow.reportName)"
                        v-if="tableRow.reportName"
                        class="iconfont icon-fuzhi1"
                    ></el-button>
                </el-form-item>
                <el-form-item label="关联客户" style="margin-left: -10px">
                    <span class="brand">{{
                        tableRow.reportName || '- -'
                    }}</span>
                </el-form-item>

                <el-form-item label="延期天数" style="margin-left: -10px">
                    <span class="brand">45天</span>
                </el-form-item>
                <el-form-item
                    label="附件"
                    style="margin-left: -10px"
                    v-if="
                        tableRow.chanceFrequencyBOList &&
                        tableRow.chanceFrequencyBOList.length >= 3
                    "
                >
                    <div style="display: flex">
                        <div
                            v-for="item in contractAttachmentList"
                            :key="item.url"
                        >
                            <el-image
                                v-if="
                                    item.url.indexOf('.jpg') != -1 ||
                                    item.url.indexOf('.png') != -1 ||
                                    item.url.indexOf('.gif') != -1
                                "
                                style="width: 100px; height: 100px"
                                :src="item.url"
                                :preview-src-list="[item.url]"
                            >
                            </el-image>
                            <span
                                class="pdf"
                                v-if="
                                    item.url.indexOf('.pdf') != -1 ||
                                    item.url.indexOf('.PDF') != -1
                                "
                                @click="onPreview(item.url)"
                                style="cursor: pointer"
                            >
                                <img src="@/img/pdfUrl.png" alt="" />
                            </span>
                            <span
                                class="word"
                                v-if="item.url.indexOf('.doc') != -1"
                                @click="onPreview(item.url)"
                                style="cursor: pointer"
                            >
                                <img src="@/img/word.png" alt="" />
                            </span>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="备注" style="margin-left: -10px">
                    <span class="brand">
                        {{
                            tableRow.nicheAuditBO &&
                            tableRow.nicheAuditBO.delayRemark
                                ? tableRow.nicheAuditBO.delayRemark || '- -'
                                : '- -'
                        }}
                    </span>
                </el-form-item>
            </el-form>
        </div>
        <div
            class="audit_remark"
            v-if="
                tableRow.nicheAuditBO &&
                tableRow.nicheAuditBO.chanceAuditBOList &&
                tableRow.nicheAuditBO.chanceAuditBOList.length > 0
            "
        >
            <h3>审批记录</h3>
            <p
                v-for="item in tableRow.nicheAuditBO.chanceAuditBOList"
                :key="item.id"
            >
                <i
                    :class="
                        item.auditStatus == 1
                            ? 'toast_title'
                            : 'toast_title delete'
                    "
                    >{{ item.auditStatus == 1 ? '通过' : '驳回' }}</i
                >
                <span
                    >{{ '审批备注' }}-{{ item.auditAdminName || '- -' }}
                    <br />
                    <i>{{ item.auditTime || '- -' }}</i>
                </span>
                <em>{{ item.auditRemark || '- -' }}</em>
            </p>
        </div>
        <div class="audit" v-if="!isDetails">
            <el-form
                ref="form"
                label-position="left"
                size="small"
                label-width="90px"
            >
                <el-form-item
                    label="处理结果"
                    required
                    style="margin-left: -10px"
                >
                    <el-radio-group v-model="form.auditStatus">
                        <el-radio :label="1">成功</el-radio>
                        <el-radio :label="2">失败</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item
                    label="到期时间"
                    required
                    v-show="!form.auditStatus || form.auditStatus == 1"
                    style="margin-left: -10px"
                >
                    <el-date-picker
                        size="small"
                        v-model="form.expireTime"
                        type="date"
                        disabled
                        value-format="yyyy-MM-dd HH:mm:ss"
                        style="width: 240px"
                        clearable
                        @change="expireTime"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                    v-show="!form.auditStatus || form.auditStatus == 1"
                    label="报备库"
                    required
                    style="margin-left: -10px"
                >
                    <el-select
                        v-model="form.libraryCategory"
                        style="width: 240px"
                        placeholder="请选择报备库"
                        disabled
                    >
                        <el-option label="伽搜" :value="1"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="处理备注" style="margin-left: -10px">
                    <el-input
                        type="textarea"
                        v-model="form.auditRemark"
                        size="small"
                        placeholder="请输入(成功/失败理由)"
                        style="width: 240px"
                        rows="6"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #666666;
                    margin-left: 270px;
                "
                @click="onClose"
                >取 消</el-button
            >

            <el-button
                v-if="!isDetails"
                round
                type="primary"
                style="
                    background: #2370eb;
                    border-color: #2370eb;
                    color: #fff;
                    margin-right: 16px;
                "
                size="mini"
                @click="onSubmit"
                >保 存</el-button
            >
        </div>
    </div>
</template>

<script>
import {
    reportAudit,
    reportUpdate,
    reportApllyList,
    reportApplyAudit,
} from '@/api/report/newReport';
export default {
    name: '',
    props: {},
    data() {
        return {
            tableRow: {},
            form: {
                auditStatus: '',
                libraryCategory: 1,
                auditRemark: '',
                expireTime: '',
            },
            followTime: '',
            radio3: '失败',
            nicheDomainDOList: [],
            accountList: [],
            surplus: 0,
            contractAttachmentList: [],
            isDetails: false,
            userType: sessionStorage.getItem('userType'),
        };
    },
    components: {},
    created() {},
    methods: {
        getData(row, isDetails) {
            this.isDetails = isDetails;
            let data = {
                param: {
                    chanceId: row.id,
                },
            };
            reportApllyList(data).then((res) => {
                if (res.code == 200) {
                    row.nicheAuditBO = res.data.list[0];
                    if (res.data.list[0].attachmentUrl) {
                        this.contractAttachmentList = JSON.parse(
                            res.data.list[0].attachmentUrl
                        );
                    }

                    this.tableRow = row;
                }
            });

            this.form.expireTime =
                this.$searchTime.getNextDate(row.reportExpireTime, -45) +
                ' ' +
                row.reportExpireTime.split(' ')[1];
        },
        clickFollow(e) {
            e === this.followTime
                ? (this.followTime = '')
                : (this.followTime = e);
            let date = new Date();
            if (e == this.followTime && e == '30天') {
                this.form.expireTime =
                    this.$searchTime.getNextDate(
                        this.tableRow.reportExpireTime,
                        -30
                    ) + ' 00:00:00';
            } else if (e == this.followTime && e == '60天') {
                this.form.expireTime =
                    this.$searchTime.getNextDate(
                        this.tableRow.reportExpireTime,
                        -60
                    ) + ' 00:00:00';
            } else if (e == this.followTime && e == '90天') {
                this.form.expireTime =
                    this.$searchTime.getNextDate(
                        this.tableRow.reportExpireTime,
                        -90
                    ) + ' 00:00:00';
            } else {
                this.form.expireTime = '';
            }
        },
        expireTime() {
            this.followTime = '';
        },
        // 复制
        onCopy(item) {
            let url = item;
            let oInput = document.createElement('input');
            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象;
            document.execCommand('Copy'); // 执行浏览器复制命令
            this.$message({
                message: '复制成功',
                type: 'success',
            });
            oInput.remove();
        },
        onSubmit() {
            if (!this.form.auditStatus) {
                return this.$message.error('请选择审核结果');
            } else {
                let data = {
                    param: {
                        chanceDO: {
                            id: this.tableRow.id,
                        },
                        chanceAuditDO: {
                            applyId: this.tableRow.nicheAuditBO.id,
                            auditStatus: this.form.auditStatus,
                            auditRemark: this.form.auditRemark,
                        },
                    },
                };
                if (
                    this.userType == 8 ||
                    this.userType == 9 ||
                    this.userType == 10
                ) {
                    if (
                        this.tableRow.chanceFrequencyBOList &&
                        this.tableRow.chanceFrequencyBOList.length == 1
                    ) {
                        data.param.chanceDO.reportStatus = 3;
                    } else {
                        data.param.chanceDO.reportStatus = 20;
                    }
                }
                if (this.userType == 15) {
                    data.param.chanceDO.reportStatus = 3;
                }
                if (this.form.auditStatus == 2) {
                    data.param.chanceDO.reportStatus = 3;
                }
                reportApplyAudit(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                        for (let i in this.form) {
                            this.form[i] = '';
                        }
                        this.followTime = '';
                        this.onClose();
                    }
                });
            }
        },
        onStop() {
            let data = {
                param: {
                    id: this.tableRow.id,
                    reportStatus: Number(this.tableRow.reportStatus) - 1,
                },
            };
            reportUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');

                    this.expireTimeBtn = '';
                    this.onClose();
                }
            });
        },
        onPreview(url) {
            if (url.indexOf('.doc') != -1 || url.indexOf('.docx') != -1) {
                window.open(
                    'https://view.officeapps.live.com/op/view.aspx?src=' + url
                );
            } else {
                window.open(url, '_blank');
            }
        },
        onClose() {
            for (let i in this.form) {
                this.form[i] = '';
            }
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
.apply_box {
    width: 100%;
    .title {
        height: 60px;
        line-height: 60px;
        padding-left: 40px;
        padding-right: 25px;
        font-size: 14px;
        font-weight: 600;
        border-bottom: 1px solid #e5e5e5;
        button {
            float: right;
            width: 80px;
            height: 32px;
            background: #2370eb;
            border-radius: 2px;
            line-height: 28px;
            padding: 0;
            color: #fff;
            margin-top: 15px;
        }
    }
    .info {
        border-bottom: 1px dashed #e5e5e5;
        padding-bottom: 20px;
    }
    .audit {
        padding-bottom: 50px;
        border-top: 1px dashed #e5e5e5;
    }
    .brand {
        display: inline-block;
        width: 240px;
        box-sizing: border-box;
        padding-left: 10px;
        color: #333333;
        font-size: 14px;
        border-radius: 2px;
    }
    .el-form {
        margin-left: 50px;
        margin-top: 20px;
    }
}
.info {
    /deep/ .el-form-item {
        margin-bottom: 0px !important;
    }
}
.bottom-but {
    width: 480px;
    line-height: 48px;
    text-align: right;
    background: #fff;
    border-top: 1px solid #dee0e3;
    z-index: 1000;
    bottom: 0;
    position: fixed;
    button {
        width: 90px;
        z-index: 1000;
    }
}
/deep/ .el-textarea__inner {
    resize: none;
}
/deep/ .el-radio-button--mini .el-radio-button__inner {
    padding: 5px 10px !important;
}
.faid {
    /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        color: #d0021b !important;
        border-color: #d0021b !important;
    }
}
.audit_remark {
    margin-left: 42px;
    margin-top: 10px;

    h3 {
        font-size: 14px;
        margin-bottom: 10px;
    }
    p {
        display: flex;
        font-size: 14px;
        color: #333333;
        display: flex;
        position: relative;
        margin-bottom: 16px;
        padding-left: 35px;
        span {
            color: #666666;
            display: block;
            flex: 1;
            white-space: nowrap;
            i {
                font-size: 12px;
                color: #999;
                font-style: normal;
            }
        }
        em {
            flex: 1;
            font-style: normal;
        }
    }
    .toast_title {
        display: block;
        width: 56px;
        height: 28px;
        position: absolute;
        top: -5px;
        left: -14px;
        background: rgba(35, 112, 235, 0.07);
        border-radius: 1px;
        border: 1px solid #2370eb;
        font-size: 18px;
        text-align: center;
        transform: scale(0.5);
        color: #2370eb;
        font-style: normal;
    }
    .delete {
        font-style: normal;
        left: -14px;
        background: rgba(208, 2, 27, 0.05);
        border-radius: 1px;
        border: 1px solid #d0021b;
        color: #d0021b;
        cursor: pointer;
    }
    .pay {
        font-style: normal;
        left: -14px;
        background: #02d04b0d;
        border-radius: 1px;
        border: 1px solid #50cc1d;
        color: #50cc1d;
        cursor: pointer;
    }
    p:before {
        content: '';
        position: absolute;
        left: 15px;
        top: -17px;
        height: 18px;
        border-left: 1px dashed #eff4fd;
    }
    :first-child:before {
        height: 0;
    }
}
</style>
