<template>
    <div class="audit_box">
        <div style="flex: 1; overflow-y: auto">
            <p class="detail_title">
                {{ reimbDetail.adminName }}提交的申报申请
            </p>
            <ReimbInfo ref="reimbInfo"></ReimbInfo>
            <p class="detail_title mingxi">报销凭证</p>
            <Detailed ref="detailed"></Detailed>
            <p
                class="detail_title audit_remark"
                v-if="
                    reimbDetail.expenseAuditList &&
                    reimbDetail.expenseAuditList.length > 0
                "
            >
                审批备注
            </p>
            <AuditRemark ref="auditRemark"></AuditRemark>

            <div class="detail_title audit">
                <div class="line"></div>
                审批
            </div>
            <el-form label-position="left" label-width="80px" size="small">
                <el-form-item label="审批结果" required>
                    <el-radio-group v-model="auditData.status">
                        <el-radio :label="1">通过</el-radio>
                        <el-radio :label="0">驳回</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="审批备注">
                    <el-input
                        style="width: 270px"
                        type="textarea"
                        rows="5"
                        v-model="auditData.remark"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div class="comment">
                <div style="min-height: 10px; background: #f0f0f0"></div>
                <div v-for="item in commentData" style="padding-bottom: 20px">
                    <p style="padding-bottom: 6px; font-size: 14px">
                        <span style="color: #2370eb">
                            <i
                                class="el-icon-user-solid"
                                style="color: #999999; margin-right: 8px"
                            ></i
                            >{{ item.adminName }}</span
                        >
                        <span style="color: #666666">
                            {{ item.createTime }}</span
                        >
                    </p>
                    <p class="comment_text">
                        <span> {{ item.comment }}</span>
                        <el-button
                            v-if="item.adminId == adminId"
                            @click="onDelete(item)"
                            type="text"
                            icon="el-icon-delete"
                            style="color: #da5120"
                        ></el-button>
                    </p>
                </div>
                <div style="display: flex">
                    <el-input
                        type="textarea"
                        placeholder="请输入内容"
                        v-model="comment"
                    >
                    </el-input>
                    <el-button
                        @click="onComment"
                        type="primary"
                        style="
                            background: #2370eb;
                            border: none;
                            margin-left: 8px;
                        "
                        >发送评论</el-button
                    >
                </div>
            </div>
        </div>
        <div slot="footer" class="bottom-but">
            <el-button
                @click="onClose"
                round
                style="
                    padding: 7px 20px;
                    width: 80px;
                    height: 26px;
                    border-radio: 26px;
                "
                size="mini"
                >取 消</el-button
            >
            <el-button
                type="primary"
                style="
                    margin-right: 15px;
                    background: #2370eb;
                    padding: 7px 20px;
                    width: 80px;
                    height: 26px;
                    border-radio: 26px;
                "
                round
                size="mini"
                @click="onSubmit"
                >确 定</el-button
            >
        </div>
    </div>
</template>

<script>
import {
    reimbAudit,
    addComment,
    commentList,
    commentDelete,
} from '@/api/reimb/reimb';
import ReimbInfo from './components/reimbInfo.vue';
import Detailed from './components/detailed.vue';
import AuditRemark from './components/auditRemark.vue';
import { declareGet } from '@/api/cost/declare.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            reimbDetail: {},
            auditData: {},
            status: '',
            comment: '',
            commentData: [],
            adminId: sessionStorage.getItem('adminId'),
        };
    },
    watch: {},
    components: {
        ReimbInfo,
        Detailed,
        AuditRemark,
    },
    created() {},
    methods: {
        async getData(data) {
            this.status = '';
            this.auditData = {};
            this.reimbDetail = data;
            data.typeStr = '';
            if (data.declareId) {
                let res = await this.declareGet(data.declareId);
                if (res.code == 200) {
                    res.data.declareDetailList.forEach((item, index) => {
                        if (index == res.data.declareDetailList.length - 1) {
                            data.typeStr += this.$cost.declareDetailType(
                                item.type
                            );
                        } else {
                            data.typeStr +=
                                this.$cost.declareDetailType(item.type) + '、';
                        }
                    });
                    console.log(data.typeStr);
                }
            }
            setTimeout(() => {
                this.$refs.reimbInfo.getData(this.reimbDetail);
                this.$refs.detailed.getData(this.reimbDetail.expenseDetailList);
                if (data.expenseAuditList && data.expenseAuditList.length > 0) {
                    this.$refs.auditRemark.getData(
                        this.reimbDetail.expenseAuditList
                    );
                }
            }, 0);
            this.getCommentList(data.id);
        },
        declareGet(id) {
            let data = {
                param: {
                    id,
                },
            };
            return declareGet(data);
        },
        async onSubmit() {
            if (this.auditData.status === '' || this.auditData.status == null) {
                return this.$message.error('请选择审批结果');
            }

            if (this.reimbDetail.declareId) {
                let res = await this.declareGet(this.reimbDetail.declareId);
                if (res.code == 200) {
                    if (
                        res.data.contract &&
                        res.data.contract.status == 3 &&
                        this.auditData.status == 1 &&
                        res.data.payWay == 1
                    ) {
                        this.$confirm(
                            '关联合同状态异常，是否确认审核？',
                            '提示',
                            {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                type: 'warning',
                            }
                        )
                            .then(() => {
                                let data = {
                                    param: {
                                        expenseId: this.reimbDetail.id,
                                        remark: this.auditData.remark,
                                        status: this.auditData.status,
                                    },
                                };
                                reimbAudit(data).then((res) => {
                                    if (res.code == 200) {
                                        this.$message.success('操作成功');
                                        this.onClose();
                                    }
                                });
                            })
                            .catch(() => {});
                    } else {
                        let data = {
                            param: {
                                expenseId: this.reimbDetail.id,
                                remark: this.auditData.remark,
                                status: this.auditData.status,
                            },
                        };
                        reimbAudit(data).then((res) => {
                            if (res.code == 200) {
                                this.$message.success('操作成功');
                                this.onClose();
                            }
                        });
                    }
                } else {
                    let data = {
                        param: {
                            expenseId: this.reimbDetail.id,
                            remark: this.auditData.remark,
                            status: this.auditData.status,
                        },
                    };
                    reimbAudit(data).then((res) => {
                        if (res.code == 200) {
                            this.$message.success('操作成功');
                            this.onClose();
                        }
                    });
                }
            } else {
                let data = {
                    param: {
                        expenseId: this.reimbDetail.id,
                        remark: this.auditData.remark,
                        status: this.auditData.status,
                    },
                };
                reimbAudit(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                        this.onClose();
                    }
                });
            }
        },
        getCommentList(id) {
            let data = {
                param: {
                    expenseId: id,
                },
            };
            commentList(data).then((res) => {
                console.log(res);
                if (res.code == 200) {
                    this.commentData = res.data.list;
                }
            });
        },
        onComment() {
            let data = {
                param: {
                    expenseId: this.reimbDetail.id,
                    comment: this.comment,
                },
            };
            if (!this.comment) {
                return this.$message.error('请输入评论');
            }
            addComment(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.comment = '';
                    this.getCommentList(this.reimbDetail.id);
                }
            });
        },
        onDelete(item) {
            let data = {
                param: {
                    id: item.id,
                },
            };
            commentDelete(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.getCommentList(this.reimbDetail.id);
                }
            });
        },
        onClose() {
            this.status = '';
            this.auditData = {};
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.audit_box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    .line {
        height: 9px;
        background: #f9f9f9;
        width: 480px;
        position: absolute;
        top: -10px;
        left: -24px;
    }
    > div {
        padding: 14px 34px 0 20px;
        font-size: 14px;
    }
    .detail_title {
        margin-bottom: 8px;
        background: #e9f2ff;
        border-radius: 2px;
        padding-left: 15px;
        font-size: 14px;
        border: 1px dashed rgba(35, 112, 235, 0.65);
        height: 30px;
        line-height: 30px;
        color: #333;
        position: relative;
    }
    .audit_remark {
        background: #e5f5dc;
        border-color: rgba($color: #9fc877, $alpha: 0.65);
    }
    .mingxi {
        border: 1px dashed rgba($color: #feb516, $alpha: 0.65);
        background-color: #fff0d0;
    }
    .audit {
        background: #fff;
        margin-top: 27px;
        border: 1px dashed rgba($color: #fff, $alpha: 0.65);
        font-weight: 600;
    }
    .bottom-but {
        width: 100%;
        height: 48px;
        background: #fff;
        display: flex;
        padding: 0;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        border-top: 1px solid #e5e5e5;
    }
}
.el-form {
    padding-left: 20px;
}
.el-button--primary.is-disabled {
    color: #fff;
    background-color: #999;
    border-color: #999;
}
.el-button--primary {
    background-color: #2370eb;
    border-color: #2370eb;
    color: #fff;
}
.comment {
    background: #fff;
    margin-top: 30px;
    padding: 14px 34px 20px 20px;
    position: relative;
    > div:first-child {
        height: 9px;
        background: #f9f9f9;
        width: 480px;
        position: absolute;
        left: -23px;
        top: -10px;
    }
}
.comment_text {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}
</style>
