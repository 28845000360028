<template>
    <div class="clue_box">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <!-- <span>审批状态</span>
                    <el-radio-group
                        v-model="searchVal.auditStatus"
                        size="small"
                    >
                        <el-radio-button
                            label="待处理"
                            @click.native.prevent="clickitem('待处理')"
                        ></el-radio-button>
                        <el-radio-button
                            label="已处理"
                            @click.native.prevent="clickitem('已处理')"
                        ></el-radio-button>
                    </el-radio-group> -->
                    <span>时间</span>
                        <el-date-picker
                            v-model="searchVal.createDate"
                            type="month"
                            size="small"
                            style="width: 165px"
                            value-format="yyyy-MM"
                            placeholder="全部"
                            @change="(pagesize = 20), (currentPage = 1), getData()"
                        >
                    </el-date-picker>
                    <Department
                        ref="department"
                        :departmentWidth="150"
                        :adminWidth="150"
                        :depSearch="btnP.depSearch"
                        :searchUser="btnP.searchUser"
                        @searchData="searchData"
                    ></Department>
                    <span>类型</span>
                    <el-cascader
                        v-model="searchVal.type"
                        :options="$searchForm.auditType()"
                        @change="(pagesize = 20), (currentPage = 1), getData()"
                        size="small"
                        style="width: 135px"
                        placeholder="请选择"
                        :show-all-levels="false"
                    ></el-cascader>
                    <span>查询</span>
                    <el-input
                        size="small"
                        style="width: 200px"
                        placeholder="人名、客户名称、充值名称"
                        v-model.trim="searchVal.name"
                        clearable
                    >
                    </el-input>
                    <el-button
                        @click="(pagesize = 20), (currentPage = 1), getData()"
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                    ></el-button>
                </p>
            </div>
        </div>
        <div class="table_title">
            <div>
                <div class="title_left_btn">
                    <span
                        :class="
                            searchVal.auditStatus == '待处理' ? 'active' : ''
                        "
                        @click="changeActive('待处理')"
                        >待处理</span
                    >

                    <span
                        :class="
                            searchVal.auditStatus == '已处理' ? 'active' : ''
                        "
                        @click="changeActive('已处理')"
                        >已处理</span
                    >
                </div>
            </div>
            <p>
                <button
                    @click="onVoid"
                    class="cancel"
                    v-if="searchVal.auditStatus == '已处理' && btnP.nullify"
                >
                    <i class="iconfont icon-zuofei" style="font-size: 12px"></i>
                    作废
                </button>
            </p>
        </div>
        <div class="table">
            <Table
                ref="table"
                @onInvoice="onInvoice"
                @onInvoiceDetails="onInvoiceDetails"
                @onDeclare="onDeclare"
                @onDeclareDetails="onDeclareDetails"
                @onDelcarePay="onDelcarePay"
                @onReimb="onReimb"
                @onReimbDetails="onReimbDetails"
                @onReimbPay="onReimbPay"
                @onRecharge="onRecharge"
                @onRechargeDetails="onRechargeDetails"
                @onRechargePay="onRechargePay"
                @onReport="onReport"
                @onReportDetais="onReportDetais"
                @totalNum="totalNum"
            ></Table>
        </div>
        <div class="page-box" v-if="isPage">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>

        <!-- 发票审批 -->
        <el-drawer
            :visible.sync="drawerInvoice"
            :direction="direction"
            :before-close="handleDrawer"
            size="480px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    发票审批
                </div>
            </template>
            <InvoiceAudit @close="handleDrawer" ref="invoiceAudit" />
        </el-drawer>
        <!-- 发票详情 -->
        <el-drawer
            :visible.sync="drawerInvoiceDetails"
            :direction="direction"
            :before-close="handleDrawer"
            size="480px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    发票详情
                </div>
            </template>
            <InvoiceDetails @close="handleDrawer" ref="invoiceDetails" />
        </el-drawer>
        <!-- 申报详情 -->
        <el-drawer
            :visible.sync="drawerDeclareDetails"
            :direction="direction"
            :before-close="handleDrawer"
            size="480px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    申报详情
                </div>
            </template>
            <DeclareDetail
                @close="handleDrawer"
                @onDeclareAudit2="onDeclareAudit2"
                ref="declareDetail"
            />
        </el-drawer>
        <!-- 申报审批 -->
        <el-drawer
            :visible.sync="drawerDeclare"
            :direction="direction"
            :before-close="handleDrawer"
            size="480px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    申报审批
                </div>
            </template>
            <DeclareAudit @close="handleDrawer" ref="declareAudit" />
        </el-drawer>
        <!-- 支付确认 -->
        <el-drawer
            :visible.sync="drawerDeclarePay"
            :direction="direction"
            :before-close="handleDrawer"
            size="480px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    支付确认
                </div>
            </template>
            <DeclarePay @close="handleDrawer" ref="declarePay" />
        </el-drawer>
        <!-- 报销详情 -->
        <el-drawer
            :visible.sync="drawerReimbDetails"
            :direction="direction"
            :before-close="handleDrawer"
            size="480px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    报销详情
                </div>
            </template>
            <ReimbDetail
                @close="handleDrawer"
                @onReimbAudit2="onReimbAudit2"
                ref="reimbDetail"
            />
        </el-drawer>
        <!-- 报销审批 -->
        <el-drawer
            :visible.sync="drawerReimb"
            :direction="direction"
            :before-close="handleDrawer"
            size="480px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    报销审批
                </div>
            </template>
            <ReimbAudit @close="handleDrawer" ref="reimbAudit" />
        </el-drawer>
        <!-- 支付确认 -->
        <el-drawer
            :visible.sync="drawerReimbPay"
            :direction="direction"
            :before-close="handleDrawer"
            size="480px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    支付确认
                </div>
            </template>
            <ReimbPay @close="handleDrawer" ref="reimbPay" />
        </el-drawer>
        <!-- 充值审核 -->
        <el-drawer
            :visible.sync="drawerRecharge"
            :direction="direction"
            :before-close="handleDrawer"
            size="477px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    充值审核
                </div>
            </template>
            <RechargeAudit
                ref="rechargeAudit"
                @close="handleDrawer"
            ></RechargeAudit>
        </el-drawer>
        <!-- 充值支付确认 -->
        <el-drawer
            :visible.sync="drawerRechargePay"
            :direction="direction"
            :before-close="handleDrawer"
            size="477px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    充值审核
                </div>
            </template>
            <RechargePay ref="rechargePay" @close="handleDrawer"></RechargePay>
        </el-drawer>
        <!-- 详情 -->
        <el-drawer
            :visible.sync="drawerRechargeDetails"
            :direction="direction"
            :before-close="handleDrawer"
            size="477px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    充值历史详情
                </div>
            </template>
            <RechargeDetail
                ref="rechargeDetail"
                @close="handleDrawer"
            ></RechargeDetail>
        </el-drawer>
        <!-- 报备延期处理 -->

        <el-drawer
            :visible.sync="drawerReportAudit"
            :direction="direction"
            :before-close="handleDrawer"
            size="477px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    报备延期处理
                </div>
            </template>
            <ReportAudit ref="reportAudit" @close="handleDrawer"></ReportAudit>
        </el-drawer>
        <!-- 报备延期详情 -->

        <el-drawer
            :visible.sync="drawerReportDetails"
            :direction="direction"
            :before-close="handleDrawer"
            size="477px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    报备详情
                </div>
            </template>
            <ReportDetail
                ref="reportDetail"
                @close="handleDrawer"
            ></ReportDetail>
        </el-drawer>
        <!-- 删除联系人 -->
        <el-dialog
            :visible.sync="dialogDelete"
            width="560px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    作废
                </div>
            </template>
            <div class="Delcontent">
                <p>确认要删除你选中的数据吗?</p>
            </div>
            <div class="but-bottom">
                <el-button
                    round
                    type="primary"
                    size="mini"
                    style="
                        background: none;
                        border-color: #d5d6df;
                        color: #666666;
                        margin-left: auto;
                    "
                    @click="handleClose"
                    >取消</el-button
                >
                <el-button
                    round
                    type="primary"
                    style="
                        background: #2370eb;
                        border-color: #2370eb;
                        color: #fff;
                        margin-right: 30px;
                    "
                    size="mini"
                    @click="onSubmitDel"
                    >确定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Table from '../table/table.vue';
import { roleList } from '@/api/customer/customer';
import { departmentList } from '@/api/admin/framework/framework.js';
import { declareGet, declareUpdate } from '@/api/cost/declare.js';
import { expenseGet } from '@/api/reimb/reimb';
import { invoiceGet, invoiceUpdate } from '@/api/contr/invoice.js';
import { getOneRecharge } from '@/api/user/launch/pay.js';
import InvoiceAudit from '../invoice/invoiceAudit.vue';
import InvoiceDetails from '../invoice/details.vue';
import DeclareDetail from '../declare/details.vue';
import DeclareAudit from '../declare/declareAudit.vue';
import DeclarePay from '../declare/pay.vue';
import ReimbDetail from '../reimb/details.vue';
import ReimbAudit from '../reimb/reimbAudit.vue';
import ReimbPay from '../reimb/pay.vue';
import RechargeAudit from '../../../../launch/recharge/audit/rechargeAudit.vue';
import RechargePay from '../../../../launch/recharge/audit/payAudit.vue';
import RechargeDetail from '../../../../launch/recharge/detail/detail.vue';
import ReportAudit from '../report/report.vue';
import ReportDetail from '../report/report.vue';
import Department from '../../../../../components/Department';
export default {
    components: {
        Table,
        InvoiceAudit,
        InvoiceDetails,
        DeclareDetail,
        DeclareAudit,
        DeclarePay,
        ReimbDetail,
        ReimbAudit,
        ReimbPay,
        RechargeAudit,
        RechargeDetail,
        RechargePay,
        ReportAudit,
        ReportDetail,
        Department,
    },
    data() {
        return {
            searchVal: {
                type: [1],
                auditStatus: '待处理',
                adminId: '',
            },
            userName: [],
            dempData: [],
            btnP: {},
            tableRow: {},
            pagesize: 20,
            total: 0,
            currentPage: 1,

            isPage: false,
            direction: 'rtl',
            drawerInvoice: false,
            drawerInvoiceDetails: false,
            drawerDeclareDetails: false,
            drawerDeclare: false,
            drawerDeclarePay: false,
            drawerReimbDetails: false,
            drawerReimb: false,
            drawerReimbPay: false,
            drawerRecharge: false,
            drawerRechargeDetails: false,
            drawerRechargePay: false,
            drawerReportAudit: false,
            drawerReportDetails: false,
            dialogDelete: false,
        };
    },

    mounted() {
        this.getBtn();
    },
    methods: {
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;

            if (sessionStorage.getItem('pageData')) {
                let pageData = JSON.parse(sessionStorage.getItem('pageData'));

                this.searchVal = pageData.searchVal;
                this.currentPage = pageData.pageNum;
                this.$refs.department.$data.departmentId =
                    pageData.searchVal.departmentId || undefined;
                this.$refs.department.$data.adminId =
                    pageData.searchVal.adminId || '';
                this.pagesize = pageData.pageSize;
                sessionStorage.removeItem('pageData');
                this.isPage = true;
            } else {
                this.isPage = true;
            }
            if (sessionStorage.getItem('searchCompanyName')) {
                this.searchVal.name =
                    sessionStorage.getItem('searchCompanyName');
            }
            if (btn.depSearch) {
                this.getDempData();
            }
            if (btn.searchUser && !btn.depSearch) {
                this.$refs.department.getUserList(
                    sessionStorage.getItem('departmentId')
                );
            }
            this.getData();
        },
        getDempData() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                this.dempData = res.data;
                if (this.searchVal.departmentId == '') {
                    this.searchVal.departmentId = Number(
                        sessionStorage.getItem('departmentId')
                    );
                }
                this.$refs.department.getData(this.btnP, res.data);
            });
        },
        searchData(data) {
            this.pagesize = 20;
            this.pageNum = 1;
            this.searchVal.departmentId = data.departmentId;
            this.searchVal.adminId = data.adminId;
            this.getData();
        },
        changeActive(i) {
            this.searchVal.auditStatus = i;
            this.pagesize = 20;
            this.pageNum = 1;
            this.getData();
        },
        // 搜索获取列表
        getData() {
            setTimeout(() => {
                this.$refs.table.getData(
                    this.searchVal,
                    this.currentPage,
                    this.pagesize,
                    this.btnP
                );
            }, 0);
        },
        totalNum(i) {
            this.total = i;
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            // this.$refs.myScroll.scrollTop = 0;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            // this.$refs.myScroll.scrollTop = 0;
            this.getData();
        },
        // 单选时间
        clickitem(e) {
            this.searchVal.auditStatus = e;
            this.currentPage = 1;
            this.pagesize = 10;
            this.getData();
        },
        // 发票处理
        onInvoice(row) {
            this.drawerInvoice = true;
            this.tableRow = row;
            this.getInvoice();
        },
        // 发票详情
        onInvoiceDetails(row) {
            this.drawerInvoiceDetails = true;
            this.tableRow = row;
            this.getInvoice();
        },
        // 申报处理
        onDeclare(row) {
            // this.drawerDeclare = true;
            // this.tableRow = row;
            // this.getDeclare();
            this.$router.push({
                path: '/declareinfodetails',
                query: {
                    id: row.id,
                },
            });
        },
        // 申报详情
        onDeclareDetails(row) {
            // this.drawerDeclareDetails = true;
            // this.tableRow = row;
            // this.getDeclare();
            this.$router.push({
                path: '/declareinfodetails',
                query: {
                    id: row.id,
                    isDetails: 1,
                },
            });
        },
        onDeclareAudit2(row) {
            this.drawerDeclareDetails = false;
            if (row.status == 4) {
                this.drawerDeclarePay = true;
            } else {
                this.drawerDeclare = true;
            }

            this.tableRow = row;
            this.getDeclare();
        },
        // 申报支付
        onDelcarePay(row) {
            this.drawerDeclarePay = true;
            this.tableRow = row;
            this.getDeclare();
        },
        // 报销处理
        onReimb(row) {
            this.drawerReimb = true;
            this.tableRow = row;
            this.getReimb();
        },
        onReimbAudit2(row) {
            this.drawerReimbDetails = false;
            if (row.status == 4) {
                this.drawerReimbPay = true;
            } else {
                this.drawerReimb = true;
            }

            this.tableRow = row;
            this.getReimb();
        },
        // 报销详情
        onReimbDetails(row) {
            this.drawerReimbDetails = true;
            this.tableRow = row;
            this.getReimb();
        },
        // 报销支付
        onReimbPay(row) {
            this.drawerReimbPay = true;
            this.tableRow = row;
            this.getReimb();
        },
        // 充值处理
        onRecharge(row) {
            this.drawerRecharge = true;
            this.tableRow = row;
            // this.getRecharge();
            setTimeout(() => {
                this.$refs.rechargeAudit.getData(row);
            }, 0);
        },
        // 充值详情
        onRechargeDetails(row) {
            this.drawerRechargeDetails = true;
            this.tableRow = row;
            setTimeout(() => {
                this.$refs.rechargeDetail.getData(row);
            }, 0);
        },
        // 充值详情
        onRechargePay(row) {
            this.drawerRechargePay = true;
            this.tableRow = row;
            setTimeout(() => {
                this.$refs.rechargePay.getData(row);
            }, 0);
        },
        // 报备延期审批
        onReport(row) {
            console.log(row);
            this.drawerReportAudit = true;
            this.tableRow = row;
            setTimeout(() => {
                this.$refs.reportAudit.getData(row, false);
            }, 0);
        },
        // 报备延期详情
        onReportDetais(row) {
            this.drawerReportDetails = true;
            this.tableRow = row;
            setTimeout(() => {
                this.$refs.reportDetail.getData(row, true);
            }, 0);
        },
        getInvoice() {
            let data = {
                param: {
                    id: this.tableRow.id,
                },
            };
            invoiceGet(data).then((res) => {
                if (res.code == 200) {
                    if (this.drawerInvoiceDetails) {
                        this.$refs.invoiceDetails.getData(res.data);
                    }
                    if (this.drawerInvoice) {
                        this.$refs.invoiceAudit.getData(res.data);
                    }
                }
            });
        },
        getDeclare() {
            let data = {
                param: {
                    id: this.tableRow.id,
                },
            };
            declareGet(data).then((res) => {
                if (res.code == 200) {
                    if (this.drawerDeclare) {
                        this.$refs.declareAudit.getData(res.data);
                    }
                    if (this.drawerDeclareDetails) {
                        this.$refs.declareDetail.getData(res.data);
                    }
                    if (this.drawerDeclarePay) {
                        this.$refs.declarePay.getData(res.data);
                    }
                }
            });
        },
        getReimb() {
            let data = {
                param: {
                    id: this.tableRow.id,
                },
            };
            expenseGet(data).then((res) => {
                if (res.code == 200) {
                    if (this.drawerReimb) {
                        this.$refs.reimbAudit.getData(res.data);
                    }
                    if (this.drawerReimbDetails) {
                        this.$refs.reimbDetail.getData(res.data);
                    }
                    if (this.drawerReimbPay) {
                        this.$refs.reimbPay.getData(res.data);
                    }
                }
            });
        },
        getRecharge() {
            let data = {
                param: {
                    id: this.tableRow.id,
                },
            };
            getOneRecharge(data).then((res) => {
                if (res.code == 200) {
                    if (this.drawerRecharge) {
                    }
                    if (this.drawerDeclareDetails) {
                        this.$refs.declareDetail.getData(res.data);
                    }
                }
            });
        },

        onVoid() {
            let tableRadio = this.$refs.table.tableRadio;
            if (tableRadio == '') {
                return this.$message.error('请选择要作废的数据');
            }
            this.dialogDelete = true;
        },
        onSubmitDel() {
            // let choiceArr = this.$refs.table.choiceArr;
            if (this.$refs.table.tableRadio.type == 1) {
                let data = {
                    param: {
                        id: this.$refs.table.tableRadio.id,
                        status: 6,
                    },
                };

                invoiceUpdate(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                        this.handleClose();
                    }
                });
            } else {
                let data = {
                    param: {
                        id: this.$refs.table.tableRadio.id,
                        status: 12,
                    },
                };

                declareUpdate(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                        this.handleClose();
                    }
                });
            }
        },
        handleDrawer() {
            this.drawerInvoice = false;
            this.drawerInvoiceDetails = false;
            this.drawerDeclareDetails = false;
            this.drawerDeclare = false;
            this.drawerDeclarePay = false;
            this.drawerReimbDetails = false;
            this.drawerReimb = false;
            this.drawerReimbPay = false;
            this.drawerRecharge = false;
            this.drawerRechargeDetails = false;
            this.drawerRechargePay = false;
            this.drawerReportAudit = false;
            this.drawerReportDetails = false;
            this.getData();
        },
        handleClose() {
            this.dialogDelete = false;
            this.getData();
        },
    },
    destroyed() {
        sessionStorage.removeItem('searchCompanyName');
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.clue_box {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        p {
            display: inline-block;
            .el_radio_button_div {
                font-size: 14px;
                width: 70px;
            }
        }
        span {
            font-size: 12px;
            font-weight: 600;
            color: #000;
            margin: 0 16px;
        }
        .search-one {
            margin: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                button {
                    background: #2370eb;
                    border-radius: 2px;
                    border-color: #2370eb;
                }
                .el-range-editor--small.el-input__inner {
                    vertical-align: middle;
                    margin-left: 19px;
                }
            }
            .right {
                background: #2370eb;
                border-radius: 2px;
                border-color: #2370eb;
                margin-right: 16px;
            }
        }
    }

    .table_title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        .title_left_btn {
            span {
                display: inline-block;
                padding: 4px 13px;
                border-radius: 20px;
                font-size: 13px;
                color: #666666;
                border: 1px solid #f0f0f0;
                cursor: pointer;
            }
            span + span {
                margin-left: 12px;
            }
            .active {
                background: #e9f2ff;
                color: #2370eb;
            }
        }
        .add {
            border-color: #d9d9d9;
            color: #fff;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }

        button {
            font-size: 12px;
            padding: 0 25px 0 17px;
            height: 26px;

            border-radius: 2px;
            border: 1px solid #d0021b;
            cursor: pointer;
            color: #fff;
            margin-left: 16px;
            background: #d0021b;
            i {
                margin-right: 6px;
                font-size: 12px;
            }
        }
    }
    .table {
        flex: 1;
        overflow-y: auto;
        background-color: #fff;
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #e5e5e5;
    }
    .clue_btn {
        margin-top: 10px;
        position: absolute;
        top: -50px;
        right: 0;
        span {
            display: inline-block;
            padding: 10px 20px;
            margin-left: 15px;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            background-color: #fff;
        }
    }
}
.clue_assist_body {
    padding: 42px 74px 100px;
    .user {
        padding-bottom: 16px;
        display: flex;
        align-items: center;
        .left-spot {
            display: inline-block;
            width: 8px;
            height: 8px;
            background: #2370eb;
            border-radius: 50%;
            margin-right: 20px;
            margin-left: -22px;
        }
    }
    .user:last-child {
        .left-spot {
            display: inline-block;
            width: 7px;
            height: 7px;
            background: #fff;
            border: 1px solid #2370eb;
            border-radius: 50%;
            margin-right: 20px;
            margin-left: -22px;
        }
    }
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    background: #fff;
    justify-content: space-between;
    button {
        width: 72px;
    }
}

.dialog_info {
    width: 80%;
    margin: 25px auto 150px;
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
}
/deep/ .el-textarea__inner {
    resize: none;
}
.search-two {
    margin-bottom: 10px;
}
.cancel {
    background-color: #d0021b;
    color: #fff;
}
.Delcontent {
    padding: 100px 80px;
    font-size: 16px;
}
.but-bottom {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    background: #fff;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
</style>
